const languageFromEdition = edition =>
  ({
    au: 'en',
    br: 'pt',
    ca: 'en',
    de: 'de',
    es: 'es',
    esp: 'es',
    fr: 'fr',
    in: 'en',
    jp: 'ja',
    mx: 'es',
    uk: 'en',
    us: 'en',
  }[edition]);

module.exports = {
  languageFromEdition,
};
