import getConfig from 'next/config';

export const LOCAL_API = '/api/';
export const ROUTED_API = '/public-feed-data/';
export const PERMUTIVE_API_KEY_BF_US = 'e275d97e-aa77-43e0-ac22-71c7d5d5ad5a';
export const PERMUTIVE_PROJECT_ID_BF_US = '6ef7b2c1-fe43-45ad-8551-5377c13e2f71';


const {
  CLUSTER,
  ENV,
  LOCAL_BFPS,
  RIG_DEPLOYMENT_TYPE,
  abeagle_host: abeagleHost,
  bf_url: bfUrl,
  facebook_tracking_id,
  google_analytics_id,
  gtm_enabled,
  gtm_id,
  permutive_creds,
  site_captcha_key,
  sentry_dsn: sentryDsn
} = getConfig().publicRuntimeConfig;

export {
  CLUSTER,
  ENV,
  LOCAL_BFPS,
  RIG_DEPLOYMENT_TYPE,
  abeagleHost,
  bfUrl,
  facebook_tracking_id,
  google_analytics_id,
  gtm_enabled,
  gtm_id,
  permutive_creds,
  site_captcha_key,
  sentryDsn
};
