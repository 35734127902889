const NextI18Next = require('next-i18next').default;
const publicRuntimeConfig = require('../../env.config');
const assetPrefix = publicRuntimeConfig.ASSET_PREFIX;
const localeHash = require('../../../locale-hash.json');
const { languageFromEdition } = require('../utils/editions');

const fromPath = {
  name: 'fromPath',
  lookup: function(req = {}) {
    /**
     * Checks if the first part of the path is an edition and returns the associated language. For
     * example, the latam edition is "mx" and the path would looks like this:
     * /mx/username/article-slug
     */
    const path = req.url || window.location.pathname;
    const editionPath = path.split('/')[1];

    return languageFromEdition(editionPath) || null;
  },
};

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['de', 'es', 'ja', 'pt'],
  localePath: 'src/public/static/locales',
  detection: {
    order: ['fromPath'],
  },
  customDetectors: [fromPath],
  backend: {
    loadPath: (lngs, namespaces) => {
      const localeKey = `${lngs}/${namespaces}`;

      return typeof window === 'undefined' || !localeHash[localeKey]
        ? 'src/public/static/locales/{{lng}}/{{ns}}.json' //server
        : `${assetPrefix}/_next/static/locales/{{lng}}/{{ns}}.${localeHash[localeKey]}.json`; // client
    },
  },
});

module.exports = NextI18NextInstance;
