module.exports = [
  // https://sentry.io/buzzfeed-6q/bpager/?query=is%3Aunresolved+Blocked+a+frame+with+origin
  // We get a lot of security errors from iframes interacting on our pages
  // Block the specific hosts rather than the error as these might sometimes be valid
  'from accessing a frame with origin "https://cdn-gl.imrworldwide.com"',
  'from accessing a frame with origin "https://tpc.googlesyndication.com"',
  'from accessing a frame with origin "https://tpc.googlesyndication.com"',
  'from accessing a frame with origin "https://staticxx.facebook.com"',
  'from accessing a frame with origin "https://trc.taboola.com"',
  'from accessing a frame with origin "https://platform.twitter.com"',
  'from accessing a frame with origin "https://6abc.com"',

  // Duplicate quiz bundle is being loaded for ads support
  // https://sentry.io/buzzfeed-6q/bpager/?query=is%3Aunresolved+has+already+been+added
  'has already been added',

  // Ad blockers cause script load failures
  'Non-Error promise rejection captured with value: Script at url https://s.pinimg.com/ct/core.js failed to load',
  '(error: https://www.buzzfeed.com/static-assets/bf-bpage-ui/_next/static/chunks/comscore',
  '(error: https://www.buzzfeed.com/static-assets/bf-bpage-ui/_next/static/chunks/beacons-external',
  '(timeout: https://www.buzzfeed.com/static-assets/bf-bpage-ui/_next/static/chunks/outbrain',
  '(timeout: https://www.buzzfeed.com/static-assets/bf-bpage-ui/_next/static/chunks/fb',

  // Ad frames sometimes do not clean up immediately
  "Can't execute code from a freed script",

  // Known JS errors from subbuzzes
  "Cannot read property 'currentTime' of null",
  "Failed to execute 'getComputedStyle' on 'Window': parameter 1 is not of type",
  "Unable to get property 'currentTime' of undefined or null reference",
  "Unable to get property 'getBoundingClientRect' of undefined or null reference",
  "Cannot read property 'getBoundingClientRect' of null",
  'Argument 1 of Window.getComputedStyle is not an object.',
  "null is not an object (evaluating 'this.el_[t]')",
  "null is not an object (evaluating 'this.el",
];
